import * as React from 'react'

import "../css/scoped/section.scss"

const Section = ({ id, background, children }) => {
	return (
		<section id={ id } className={`page-section ${background}`}>
			<div>
				{children}
			</div>
		</section>
	)
}

export default Section