import * as React from 'react'
import Nav from '../components/Nav'
import Brand from '../components/Brand'

const Header = () => {
	return (
		<header>
        	<Brand />
        	<Nav />
    	</header>
	)
  }
  
export default Header