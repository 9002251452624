import * as React from 'react'
import { Link } from 'gatsby'

import "../css/scoped/nav.scss"

// data
const navLinks = [
	{
	  text: "About 52 Small Birds",
	  url: "/",
	  hash: "#book",
	  key: 1,
	},
	{
	  text: "The author",
	  url: "/",
	  hash: "#author",
	  key: 2,
	},
	{
	  text: "Other books",
	  url: "/",
	  hash: "#other-books",
	  key: 3,
	},
	{
	  text: "More paintings",
	  url: "/art",
	  hash: "",
	  key: 4,
	},
]

const Nav = () => {
  return (
	<nav className="main-nav">
		<ul>
		{ navLinks.map( link => (
			<li key={link.key}>
				<Link to={`${link.url}${link.hash}`}>{link.text}</Link>
			</li>
		))}
		</ul>
	</nav>
  )
}

export default Nav

