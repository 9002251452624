import * as React from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'

import "../css/scoped/layout.scss"

const Layout = ({ pageTitle, children }) => {
  return (
    <div className="page-container">
	  <Header />
      <title>{pageTitle}</title>
      <main>
        {children}
      </main>
	  <Footer />
    </div>
  )
}

export default Layout