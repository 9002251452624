import * as React from 'react'

import "../css/scoped/footer.scss"

const Footer = () => {
	return (
		<footer>
			<div>
				<p>Copyright © 2021 Richard Weeks</p>
				<p>Published by <a target="_blank" rel="noreferrer" href="https://www.luminarepress.com">Luminare Press</a></p>
			</div>
    	</footer>
	)
}
  
export default Footer