import * as React from "react"
import Layout from '../components/Layout'
import Section from '../components/Section'
import { StaticImage } from "gatsby-plugin-image"

const MildPage = () => {
  
	return (
		<Layout pageTitle="A book: Mild">
			<Section id="book-intro" background="light">
				<div className="flex small-image">
					<div className="image-wrapper">
						<StaticImage
						src="../images/mild-cover.png"
						alt="Cover of the book Mild by Richard Weeks"
						placeholder="blurred"
						/>
					</div>
					<div className="content-wrapper">
						<h2>Mild</h2>
						<p><strong>Published: 2017</strong></p>
						<p>This second collection of memoirs begins in the 1950s with a story about delivering newspapers in suburban Los Angeles and concludes in 2016 with what had been planned as a birthday party/presidential election celebration. In what may serve as a theme for the book, neither story turned out as expected.</p>
						<p>The longest chapter is devoted to the six summers from 1989 to 1995 that I spent hiking the Pacific Crest Trail in Oregon. As my friends and I hiked and camped, I filled a diary with sketches and descriptions of the experience. We didn't know it at the time, but just two weeks ahead of us on the trail was a young woman named Cheryl Strayed.</p>
						<p>She hiked alone and, like me, kept a diary. After her memoir came out in 2012, my hiking companions and I couldn't help but wonder what might have happened if we had met Cheryl along the way. Perhaps if she had devoted a few paragraphs to describing her encounter in southern Oregon with three fascinating geezers, she might have had enough material to produce a bestseller. I guess we will never know.</p>
					</div>
				</div>
			</Section>
	    </Layout>
	)
}

export default MildPage