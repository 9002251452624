import * as React from 'react'
import { Link } from 'gatsby'

import "../css/scoped/brand.scss"

const Brand = () => {
	return (
		<div className="brand">
          <h1>
            <Link to="/">Richard Weeks</Link>
          </h1>
          <p>artist-author</p>
        </div>
	)
}

export default Brand